import BackgroundGlow from './components/BackgroundGlow.js';
import Header from './views/header/Header.js';
import Hero from './views/hero/Hero.js';
import About from './views/about/About.js';
import Services from './views/services/Services.js';
import Reviews from './views/reviews/Reviews.jsx';
import Contact from './views/contact_form/Contact.js';
import Footer from './views/footer/footer.jsx';
import './App.css';
import { useRef } from 'react';

function App() {
  const navRef = useRef();
  return (
    <div ref={navRef} className="App">
      {/* <meta name="viewport" content="width=device-width, initial-scale=0" /> */}
      {/* <meta name="viewport" content="width=1000" /> */}
      {/* <BackgroundGlow /> */}
      <Header />
      <div className='hero'>
        <Hero />
      </div>
      <div className='about'>
        <About />
      </div>
      <div className='services'>
        <Services />
      </div>
      <div className='reviews'>
        <Reviews />
      </div>
      <div className='contact'>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default App;
