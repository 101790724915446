import './reviews.css';

export default function Reviews() {
    return (
        <div className='reviews'>
            <div className='reviews__content'>
                <p className='text__heading'>Client Testimonials</p>
                <p className='text__subheading'>Read what our clients have to say</p>

                {/* review 1 */}
                <div className='reviews__card'>
                    <div className='reviews__card__header'>
                        <p className='reviews__card__header__text'>“They are fanatically conscientious. I wouldn’t hesitate to recommend them for any job, big or small.”</p>
                    </div>
                    <div className='reviews__card__footer'>
                        <p className='reviews__card__clientname'>Alan</p>
                        <p className='reviews__card__clientaddress'>Telegraph Hill</p>
                    </div>
                </div>

                {/* review 2 */}
                <div className='reviews__card'>
                    <div className='reviews__card__header'>
                        <p className='reviews__card__header__text'>“They have been essential for refurbishing my property, ensuring it is quickly ready for the rental market. Going above what was necessary. Transparent, flexible on cost, and easily contactable. Would absolutely recommend.”</p>
                    </div>
                    <div className='reviews__card__footer'>
                        <p className='reviews__card__clientname'>Lee</p>
                        <p className='reviews__card__clientaddress'>Forest Hill</p>
                    </div>
                </div>

                {/* review 3 */}
                <div className='reviews__card'>
                    <div className='reviews__card__header'>
                        <p className='reviews__card__header__text'>“They worked tirelessly in our garden to give it a new lease of life. A flexible and friendly service, going above and beyond our expectations.”</p>
                    </div>
                    <div className='reviews__card__footer'>
                        <p className='reviews__card__clientname'>Tom and Liz</p>
                        <p className='reviews__card__clientaddress'>Hither Green</p>
                    </div>
                </div>

                {/* review 4 */}
                <div className='reviews__card'>
                    <div className='reviews__card__header'>
                        <p className='reviews__card__header__text'>“The quality of their fencing work was excellent, particularly in the awkward areas. We are pleased to have found someone capable of doing such a variety of work, and would have no hesitation to use them again.”</p>
                    </div>
                    <div className='reviews__card__footer'>
                        <p className='reviews__card__clientname'>Rosie</p>
                        <p className='reviews__card__clientaddress'>Forest Hill</p>
                    </div>
                </div>

                {/* review 5 */}
                <div className='reviews__card'>
                    <div className='reviews__card__header'>
                        <p className='reviews__card__header__text'>“None of the jobs that they have taken on have been straight-forward. But their work, speed, and quality of finish, have been excellent each time. I’ll certainly use them again.”</p>
                    </div>
                    <div className='reviews__card__footer'>
                        <p className='reviews__card__clientname'>Ben</p>
                        <p className='reviews__card__clientaddress'>Forest Hill</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
