import './footer.css';
import PhoneIcon from '../../assets/svg/PhoneIcon';
import MailIcon from '../../assets/svg/MailIcon';
import PinIcon from '../../assets/svg/PinIcon';

export default function Footer() {
    return (
        <div className='footer-view'>
            <p className='footer__text'>Copyright 2025, all rights reserved</p>
        </div>
    )
}

