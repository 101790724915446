import '../../const/styles.css';
import './contactStyles.css';
import ContactForm from './components/ContactForm.js';
import PhoneIcon from '../../assets/svg/PhoneIcon';
import MailIcon from '../../assets/svg/MailIcon';
import PinIcon from '../../assets/svg/PinIcon';
import ContactFormQR from '../../assets/svg/ContactFormQR.js';

export default function Contact() {
    return (
        <div className='contact-view'>
            <div className='contact__content'>
                <p className='text__heading'>Contact Us</p>
                <p className='text__subheading contact__text'>Give us a shout, and we'll sort it out</p>
                <p className='text__subheading contact__text'>Get in touch with us directly, or fill our 30-second form below!</p>

                <div className='contact__details'>
                    <div className='contact__details--item' >
                        <PhoneIcon width="3vw" height="3vw" stroke={"var(--black)"} />
                        <h5 className='contact__details--text'>07809220621</h5>
                    </div>
                    <div className='contact__details--item' >
                        <MailIcon width="3vw" height="3vw" stroke={"var(--black)"} />
                        <h5 className='contact__details--text'>alex@svarex.co.uk</h5>
                    </div>
                    <div className='contact__details--item' >
                        <PinIcon width="3vw" height="3vw" stroke={"var(--black)"} />
                        <h5 className='contact__details--text'>SE LDN</h5>
                    </div>
                </div>
                

                <div className='contact_methods'>

                    <a
                        className='button anchor_button_styling'
                        href='https://forms.office.com/r/jnxSebB7wD'
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Click to visit our form
                    </a>

                    <div className='contact_methods__qr_code_container'>
                        <div className='qr_code'>
                            <ContactFormQR fill="black" />
                        </div>
                    </div>


                </div>


            </div>
            

            {/* <ContactForm /> */}

        </div>
    )
}