import './services.css';

export default function Services() {
    return (
        <div className="services">
            <div className='services__content'>
                <p className="text__heading">Our Services</p>
                <h7 className="services__text">
                    <p>We provide a wide variety of services for your property</p>
                    <p>From interior decoration, exterior renovation, fitting kitchens, to loft conversions and building conservatories</p>
                    <p>We would love to hear from you, let us know you're interested below!</p>
                </h7>

                {/* Connect with us button */}
                <button className='button button--contact' onClick={() => {
                    const section = document.querySelector('.contact')
                    section.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                    }); // https://stackoverflow.com/a/62173627
                }}>
                    Connect with us
                </button>

                <h7 className="services__text">
                    <p>Take a glimpse of our work, if anything tickles your fancy, you know where to find us</p>
                </h7>
    
                <div className='grid'>
                    {/* row 1 */}
                    <div className='card' style={{'background-color': '#AE847F'}}>
                        <div className='card__footer' style={{'background-color': '#775753'}} />
                        {/* import image from public folder */}
                        <img className='card__image' src={'/assets/img/stairs.png'} /> 
                    </div>
                    <div className='card' style={{'background-color': '#344451'}}>
                        <div className='card__footer' style={{'background-color': '#CA8319'}} />
                        {/* import image from public folder */}
                        <img className='card__image' src={'/assets/img/living_room.png'} /> 
                    </div>
                    <div className='card' style={{'background-color': '#8D8173'}}>
                        <div className='card__footer' style={{'background-color': '#99552F'}} />
                        {/* import image from public folder */}
                        <img className='card__image' src={'/assets/img/kitchen_countertops.png'} /> 
                    </div>

                    {/* row 2 */}
                    <div className='card' style={{'background-color': '#578CD8'}}>
                        <div className='card__footer' style={{'background-color': '#F0EDE6'}} />
                        {/* import image from public folder */}
                        <img className='card__image' src={'/assets/img/sussex_before.png'} /> 
                    </div>
                    <div className='card' style={{'background-color': '#C3DCAF'}}>
                        <div className='card__footer' style={{'background-color': '#B3927F'}} />
                        {/* import image from public folder */}
                        <img className='card__image' src={'/assets/img/garden_wall_3.png'} /> 
                    </div>
                    <div className='card' style={{'background-color': '#243954'}}>
                        <div className='card__footer' style={{'background-color': '#AAAFB0'}} />
                        {/* import image from public folder */}
                        <img className='card__image' src={'/assets/img/landing_quarter.png'} /> 
                    </div>

                    {/* row 3 */}
                    <div className='card' style={{'background-color': '#97919F'}}>
                        <div className='card__footer' style={{'background-color': '#FCF3EA'}} />
                        {/* import image from public folder */}
                        <img className='card__image' src={'/assets/img/garden_4.png'} /> 
                    </div>
                    <div className='card' style={{'background-color': '#B4A087'}}>
                        <div className='card__footer' style={{'background-color': '#E4E4F1'}} />
                        {/* import image from public folder */}
                        <img className='card__image' src={'/assets/img/after_4.png'} /> 
                    </div>
                    <div className='card' style={{'background-color': '#CAAC90'}}>
                        <div className='card__footer' style={{'background-color': '#5B4A31'}} />
                        {/* import image from public folder */}
                        <img className='card__image' src={'/assets/img/gate.png'} /> 
                    </div>

                </div>
            </div>
        </div>
    );
}
