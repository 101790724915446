import './about.css';
import '../../const/styles.css';
import TreeArt from '../../assets/svg/TreeArt';

export default function About() {
    return (
        <div className="about">
            <div className='about__content'>
                <p className='text__heading'>About Us</p>
                <h7>
                    <p>SVAREX is a business dedicated to bringing the best out of your property. Offering a plethora of services from kitchen installations to general property refurbishment</p>
                    <p>We pride ourselves in never settling for anything less than high-quality work, leaving you with the peace of mind that the job will be done right. Cutting corners is not what we're about</p>
                    <p>Through transparency and clear communication, we are sure we'll be able to tailor to your needs and wishes</p>
                    <p>With over a decade of experience and plenty of satisfied customers, we are sure you would be too</p>
                </h7>
                
                {/* Read customer reviews Button */}
                <button className='button button--reviews' onClick={() => {
                    const section = document.querySelector('.reviews')
                    section.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                    }); // https://stackoverflow.com/a/62173627
                }}>
                    Read Reviews
                </button>

                <div className='tree_art_container'>
                    <TreeArt />
                </div>
                <h7>We understand the importance of sustainability, and choose more sustainable options where appropriate</h7>
                {/* <button className='button button--sustainability'>Learn more about sustainability</button> */}
            </div>
        </div>
    )
}

