import * as React from "react";

const TreeArt = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 557 422"
    margin="auto"
    {...props}
  >
    <circle cx={365} cy={192} r={38} fill="#FFF500" fillOpacity={0.8} />
    <g filter="url(#a)">
      <circle cx={365} cy={192} r={92} fill="#FFF500" fillOpacity={0.8} />
    </g>
    <path
      fill="#296134"
      fillOpacity={0.5}
      stroke="#296134"
      strokeWidth={10}
      d="M281 286.5c0 44.476-28.536 78.5-61.5 78.5S158 330.976 158 286.5s28.536-78.5 61.5-78.5 61.5 34.024 61.5 78.5Z"
    />
    <path
      fill="#296134"
      d="M223 265a5 5 0 0 0-10 0h10Zm-10 129v5h10v-5h-10Zm0-129v129h10V265h-10Z"
    />
    <path
      stroke="#296134"
      strokeLinecap="round"
      strokeWidth={10}
      d="M248 394h-59M234 342l-15 10M217 285l-15-10M233 290l-15 10M216 330l-15-10"
    />
    <circle
      cx={139.5}
      cy={294.5}
      r={71.5}
      fill="#43754D"
      fillOpacity={0.5}
      stroke="#43754D"
      strokeWidth={10}
    />
    <path
      fill="#43754D"
      d="M143 293.5a5 5 0 0 0-10 0h10Zm-10 100v5h10v-5h-10Zm0-100v100h10v-100h-10Z"
    />
    <path
      stroke="#43754D"
      strokeLinecap="round"
      strokeWidth={10}
      d="M168 394h-59M154 320l-15 10M137 300l-15-10M153 290l-15 10M136 330l-15-10"
    />
    <path
      fill="#76947C"
      fillOpacity={0.5}
      stroke="#76947C"
      strokeWidth={10}
      d="M282.147 232.968c6.958-16.07 29.748-16.07 36.706 0l41.173 95.085c5.718 13.205-3.963 27.947-18.353 27.947h-82.346c-14.39 0-24.071-14.742-18.353-27.947l41.173-95.085Z"
    />
    <path
      fill="#76947C"
      d="M304 265a5 5 0 0 0-10 0h10Zm-10 129v5h10v-5h-10Zm0-129v129h10V265h-10Z"
    />
    <path
      stroke="#76947C"
      strokeLinecap="round"
      strokeWidth={10}
      d="M329 394h-59M315 342l-15 10M298 285l-15-10M314 290l-15 10M297 330l-15-10"
    />
    <circle
      cx={392.586}
      cy={301.586}
      r={14.586}
      fill="#FFA800"
      fillOpacity={0.8}
    />
    <path
      fill="#FFA800"
      fillOpacity={0.8}
      d="M52.586 372.414C52.586 380.47 46.056 387 38 387c-8.055 0-14.586-6.53-14.586-14.586 0-8.055 6.53-14.586 14.586-14.586 8.056 0 14.586 6.531 14.586 14.586ZM29.236 284.414c0 8.056-6.53 14.586-14.586 14.586-8.056 0-14.586-6.53-14.586-14.586 0-8.055 6.53-14.586 14.586-14.586 8.055 0 14.586 6.531 14.586 14.586Z"
    />
    <defs>
      <filter
        id="a"
        width={384}
        height={384}
        x={173}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_5_2730"
          stdDeviation={40}
        />
      </filter>
    </defs>
  </svg>
)

export default TreeArt
