import React, { useState, useEffect } from 'react';
import './header.css';
import { use } from 'react';

export default function Header() {
    // hide or show header based on scroll
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    // expand header if screen too narrow
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen((open) => !open);
    };

    // https://stackoverflow.com/a/71356027
    // https://stackoverflow.com/a/77852881
    /* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
    const controlNavbar = () => {
        if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
          setShow(false); 
        } else { // if scroll up show the navbar
          setShow(true);  
        }
    
        // remember current page location to use in the next move
        setLastScrollY(window.scrollY); 
    };

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);
    
        // cleanup function
        return () => {
           window.removeEventListener('scroll', controlNavbar);
        };
      }, [lastScrollY]);


    return (
        // <div className="header-container">
        <div className={`header-container ${show ? '' : 'is-hidden'}`}>
            <div className='header__row1'>
                <icon className='logo'>SVAREX</icon>
                <div className={`burger-menu-btn ${isOpen ? 'is-open' : ''}`} onClick={toggleMenu}>
                    <div className={'burger-menu-btn__bar'}></div>
                    <div className={'burger-menu-btn__bar'}></div>
                    <div className={'burger-menu-btn__bar'}></div>
                </div>
            </div>
            <nav>
                {/* trigger */}
                {/* <li className='dvs-header__trigger' onClick={toggleMenu}>TRIGGER</li> */}

                {/* links */}
                <ul className={`nav__links ${isOpen ? 'is-open' : ''}`}>
                    {/* ABOUT button */}
                    <button onClick={() => {
                        const section = document.querySelector('.about')
                        section.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                        }); // https://stackoverflow.com/a/62173627
                    }}>
                        ABOUT
                    </button>
                    {/* SERVICES button */}
                    <button onClick={() => {
                        const section = document.querySelector('.services')
                        section.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                        }); // https://stackoverflow.com/a/62173627
                    }}>
                        SERVICES
                    </button>
                    {/* TESTIMONIALS button */}
                    <button onClick={() => {
                        const section = document.querySelector('.reviews')
                        section.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                        }); // https://stackoverflow.com/a/62173627
                    }}>
                        TESTIMONIALS
                    </button>
                    {/* CONTACT button */}
                    <button onClick={() => {
                        const section = document.querySelector('.contact')
                        section.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                        }); // https://stackoverflow.com/a/62173627
                    }}>
                        CONTACT
                    </button>
                </ul>
            </nav>
        </div>
    );
}
